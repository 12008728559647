import Api from '@/apis/index'

class Server extends Api {
  getOrders (params) {
    return this.$http.get(`${Api.path.apiJob}/partner/${this.userInfo.partnerId}/orders`, { params })
  }

  getCommissions (params) {
    return this.$http.get(`${Api.path.apiJob}/partner/${this.userInfo.partnerId}/commissions`, { params })
  }

  getPayouts (params) {
    return this.$http.get(`${Api.path.apiJob}/partner/${this.userInfo.partnerId}/commission-orders`, { params })
  }
}

export default new Server()
