<template>
	<div class="finance-manage">
		<el-tabs v-model="currentTab" type="card">
			<el-tab-pane label="下单记录" name="order">
				<jw-table ref="order" v-bind="orderTable" @search="getOrderList" />
			</el-tab-pane>
			<el-tab-pane label="分佣记录" name="commission">
				<jw-table ref="commission" v-bind="commionTable" @search="getCommissionList" />
			</el-tab-pane>
			<el-tab-pane label="提现记录" name="payouts">
				<jw-table ref="payouts" v-bind="payoutsTable" @search="getPayoutList" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>
<script lang="jsx">
import Server from './apis'
import dayjs from 'dayjs'
export default {
  data () {
    return {
      currentTab: 'order',
      orderTable: {
        data: [],
        page: {
          total: 0
        },
        cols: [
          {
            label: '头像',
            fuc: (row, h) => {
              return <img class='avatar' width='40px' src={row.avatar} />
            }
          },
          {
            label: '姓名',
            prop: 'nickname'
          },
          {
            label: '下单类型',
            prop: 'type',
            fuc: (row, h) => {
              return { 1: '月度会员', 2: '季度会员', 3: '年度会员' }[row.type]
            }
          },
          {
            label: '下单金额',
            prop: 'money'
          },
          {
            label: '下单时间',
            prop: 'payTime',
            fuc: (row, h) => dayjs(row.payTime).format('YYYY-MM-DD HH:mm:ss')
          }

        ]
      },
      commionTable: {
        data: [],
        page: {
          total: 0
        },
        cols: [
          {
            label: '头像',
            fuc: (row, h) => {
              return <img class='avatar' width='40px' src={row.avatar} />
            }
          },
          {
            label: '姓名',
            prop: 'nickname'
          },
          {
            label: '分佣金额',
            prop: 'money'
          },
          {
            label: '分佣时间',
            prop: 'payTime',
            fuc: (row, h) => dayjs(row.payTime).format('YYYY-MM-DD HH:mm:ss')
          }

        ]
      },
      payoutsTable: {
        data: [],
        page: {
          total: 0
        },
        cols: [
          {
            label: '头像',
            fuc: (row, h) => {
              return <img class='avatar' width='40px' src={row.avatar} />
            }
          },
          {
            label: '姓名',
            prop: 'nickname'
          },
          {
            label: '提现金额',
            prop: 'money'
          },
          {
            label: '提现状态',
            prop: 'status',
            fuc: (row, h) => {
              return <span style={{ color: { 0: '#E6A23C', 1: '#67C23A', 2: '#F56C6C' }[row.status] }}>{{ 2: '提现失败', 0: '提现中', 1: '提现成功' }[row.status]}</span>
            }
          },
          {
            label: '提现时间',
            prop: 'payTime',
            fuc: (row, h) => dayjs(row.gmtCreate).format('YYYY-MM-DD HH:mm:ss')
          }

        ]
      }
    }
  },
  mounted () {
    this.$refs.order.search()
    this.$refs.commission.search()
    this.$refs.payouts.search()
  },
  methods: {
    getOrderList ({ page, size }) {
      Server.getOrders({ pageIndex: page, pageSize: size }).then(res => {
        this.orderTable.data = res.data.orders
        this.orderTable.page.total = res.data.page.total
      })
    },
    getCommissionList ({ page, size }) {
      Server.getCommissions({ pageIndex: page, pageSize: size }).then(res => {
        this.commionTable.data = res.data.commissions
        this.commionTable.page.total = res.data.page.total
      })
    },
    getPayoutList ({ page, size }) {
      Server.getPayouts({ pageIndex: page, pageSize: size, status: -2 }).then(res => {
        this.payoutsTable.data = res.data.commissions
        this.payoutsTable.page.total = res.data.page.total
      })
    }
  }
}
</script>

<style lang="less" scoped>
.finance-manage {
  padding: 20px;
  /deep/ .avatar {
    border-radius: 100%;
  }
}
</style>
